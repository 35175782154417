import React from "react"
import LayoutStatic from '../components/LayoutStatic'

const PageNotFound = (props) => (
    <LayoutStatic {...props} title="Page Not Found">
        <h2>404</h2>
        <article>
            <p style={{ textAlign: "center"}}>Page Not Found.</p>
        </article>
    </LayoutStatic>
)

export default PageNotFound
